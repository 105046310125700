<template>
  <div>
    <Navbar />
    <div v-scroll-spy>
      <!-- Hero Start -->
      <section
        class="hero-1-bg"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/back-img4.jpg') + ')',
        }"
        id="home"
      >
        <!-- <marquee style="color:red" behavior="" direction=""
          >{{ $t("Websiteintest") }}
        </marquee> -->
        <b-overlay :show="show">
          <div class="creators" style="padding: 0px 120px;">
            <div>
              <h4 class="creators-title">Ijodkorlar</h4>
            </div>
            <carousel :perPage="7" :navigationEnabled="true">
              <slide style="margin: 10px;" v-for="(item, index) in ArtistsList" :key="index">
                <b-card class="artist-img">
                  <img :src="`data:image/png;base64,`+ item.photobase64" alt="">
                  <p @click="View(item)" class="artist-name">{{ item.personname }}</p>
                </b-card>
              </slide>
  
            </carousel>
          </div>
        </b-overlay>
        <Footer />
      </section>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
import CreatorsService from "../../services/creators.sevice";
import { Carousel, Slide } from 'vue-carousel';
export default {
  components: {
    Navbar,
    Carousel,
    Slide,
    Footer
  },
  data() {
    return {
      ArtistsList: [],
      show: false,
      filter: {
        documentseries: "",
        documentnumber: "",
        dateofbirth: "",
        pinfl: "",
        personname: "",
        employment: "",
        orginn: "",
        workplace: "",
        positionname: "",
        teamname: "",
        roleintheteam: "",
        ignitionofcreativity: "",
        genre: "",
      }
    }
  },
  created() {
    (this.filter.pinfl = this.$route.query.pinfl || this.filter.pinfl)
    this.GetListCreators()
  },
  methods: {
    GetListCreators(){
      this.show = true
      CreatorsService.GetList().then((res) => {
        this.ArtistsList = res.data.result
      })
      this.show = false
    },
    View(item) {
      this.$router.push({
        name: "CreatorsView",
        params: { id: item.ordernumber },
      });
    }
  },
}
</script>

<style>

</style>